<template>
    <div class="">
        <p>Check that your button resource agrees with the same measurements that your home layout allows:</p>
        <div v-for="layout in layouts" :key="layout.type">
            <p>
                <b>{{ layout.type | capitalize }} layout allowed sizes:</b>
                <ul class="advice-list">
                    <li v-for="index in layout.values.columns_number" :key="index">{{ index }} column item size:  {{ getColumnWidth(layout, index) }}x{{ getColumnHeight(layout) }}px</li>
                </ul>
            </p>
        </div>
        <p>
            <b>Other restrictions:</b>
            <ul class="advice-list">
                <li>Max weight of 500KB.</li>
                <li>Only png.</li>
            </ul>
        </p>
    </div>
</template>

<script>
import ConfiguratorHomeLayoutService from "@/modules/Apps/Services/Configurator/ConfiguratorHomeLayoutService";
import PaginationService from "@/modules/Shared/Services/PaginationService";

export default {
    components: {},
    props: {},
    data() {
        return {
            layouts: [],
        };
    },
    methods: {
        async fetchVersionHomeLayouts() {
            let params = {
                page: 1,
                filters: [ { field: "version_uuid", value: this.$route.params.versionUuid }, ],
            };

            let response = await ConfiguratorHomeLayoutService.getHomeLayoutList(
                PaginationService.parseParameters(params)
            );

            return response.data
        },
        getColumnWidth(layout, columnNumberDesired) {
            // image width = dpi * (desired_item_columns) * ((375 - (padding * 2) - (spacing * (columns_number - 1)) ) / columns_number) + dpi * (desired_item_columns - 1) * spacing
            let dpi = 4
            let padding = layout.values.padding
            let spacing = layout.values.spacing
            let columnNumber = layout.values.columns_number
            return dpi * columnNumberDesired * ((375 - (padding * 2) - (spacing * (columnNumber - 1))) / columnNumber) + dpi * (columnNumberDesired - 1) * spacing
        },
        getColumnHeight(layout) {
            return layout.values.height * 4
        }
    },
    async created() {
        this.layouts = await this.fetchVersionHomeLayouts()
    },
    
};
</script>
<style scoped>
    .advice-list {
        @apply list-disc my-3 mr-0 ml-7;
    }
</style>