var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("p", [
        _vm._v(
          "Check that your button resource agrees with the same measurements that your home layout allows:"
        )
      ]),
      _vm._l(_vm.layouts, function(layout) {
        return _c("div", { key: layout.type }, [
          _c("p", [
            _c("b", [
              _vm._v(
                _vm._s(_vm._f("capitalize")(layout.type)) +
                  " layout allowed sizes:"
              )
            ]),
            _c(
              "ul",
              { staticClass: "advice-list" },
              _vm._l(layout.values.columns_number, function(index) {
                return _c("li", { key: index }, [
                  _vm._v(
                    _vm._s(index) +
                      " column item size: " +
                      _vm._s(_vm.getColumnWidth(layout, index)) +
                      "x" +
                      _vm._s(_vm.getColumnHeight(layout)) +
                      "px"
                  )
                ])
              }),
              0
            )
          ])
        ])
      }),
      _vm._m(0)
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("Other restrictions:")]),
      _c("ul", { staticClass: "advice-list" }, [
        _c("li", [_vm._v("Max weight of 500KB.")]),
        _c("li", [_vm._v("Only png.")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }