<template>
    <div class="bg-white py-5 px-10">
        <div class="flex flex-row vx-col w-full text-right items-center justify-between space-x-4 mb-4 mt-4">
            <div class="flex flex-col text-left pt-5 pb-5">
                <h3>{{ $t("apps.configurator.resources.gallery.title") }}</h3>
                <span>{{ $t("apps.configurator.resources.gallery.subtitle") }}</span>
            </div>
            <div>
                <button class="inline-block mb-2 mr-2 rounded-lg sk-btn sk-btn-primary" @click.stop="createResource">
                    {{ $t("apps.configurator.customer-gallery.upload-resource") }}
                </button>
            </div>
        </div>
        <emptyAlert v-if="!customResources.length" :message="$t('apps.configurator.customer-gallery.no-assets')" />
        <div v-if="customResources.length" class="card-container rounded-lg vs-con-textarea px-5 py-5 flex flex-row flex-wrap w-full items-center">
            <div class="w-1/4 py-3 px-2" v-for="customResource in customResources" :key="customResource">
                <CustomerAsset :asset="customResource" @editResource="editResource" @reloadCustomerResources="reloadCustomerResources"/>
            </div>
        </div>
        <div>
            <modalCustomerResource ref="modalCustomerResource" :resource="resource" :action="modalCustomerResourceAction" @reloadCustomerResources="reloadCustomerResources"/>
        </div>
    </div>
</template>

<script>
import ResourcesService from '@/modules/Apps/Services/Configurator/ConfiguratorResourcesService'
import loader from "@/modules/Shared/Mixins/loader";
import CustomerAsset from '@/modules/Apps/Components/resources/gallery/CustomerAsset'
import modalCustomerResource from "@/modules/Shared/Components/modal/ModalCustomerResource";
import emptyAlert from '@/modules/Shared/Components/alert/emptyAlert'


export default {
    name: 'CustomerGallery',
    components: {
        ResourcesService,
        CustomerAsset,
        modalCustomerResource,
        emptyAlert,
    },
    mixins: [loader],
    data() {
        return {
            customResources: [],
            modalCustomerResourceAction: 'create',
            resource: {
                url: null,
                filename: null
            }
        }
    },
    async created() {
        await this.load(async () => {
            await this.getCustomerResources()
        });
    },
    methods: {
        async getCustomerResources() {
            let response =  await ResourcesService.getCustomerResources(this.$route.params.versionUuid)
            this.customResources = response;
        },
        uploadResource() {
            this.resource.url = null
            this.resource.filename = null
        },
        async reloadCustomerResources() {
            await this.load(async () => {
                this.customResources = []
                this.resource.url = null
                this.resource.filename = null
            
                await this.getCustomerResources()
            });
        },
        createResource() {
            this.modalCustomerResourceAction = 'create'
            this.resource = {
                url: null,
                filename: null
            }
            this.$refs['modalCustomerResource'].open()
        },
        editResource(resource) {
            this.modalCustomerResourceAction = 'edit'
            this.resource = resource
            this.$refs['modalCustomerResource'].open()
        }
    }
}
</script>