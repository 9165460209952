var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "asset-card rounded-lg vs-con-textarea" },
    [
      _c("img", { attrs: { src: _vm.url } }),
      _c("SKTooltip", { attrs: { text: _vm.assetName } }, [
        _c("span", { staticClass: "block p-3 text-xs" }, [
          _vm._v(_vm._s(_vm.assetName))
        ])
      ]),
      _c("span", { staticClass: "flex pl-3 pb-3 text-xs text-grey" }, [
        _vm._v(_vm._s(_vm.resolution))
      ]),
      _c(
        "div",
        { staticClass: "mt-1 mb-3" },
        [
          _c(
            "vs-button",
            {
              staticClass: "px-3 py-2 rounded-lg mr-1 ml-3",
              attrs: { type: "border" },
              on: { click: _vm.downloadResource }
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-nowrap" },
                [
                  _c("feather-icon", {
                    staticClass: "align-middle",
                    attrs: {
                      icon: "DownloadIcon",
                      svgClasses: "text-primary align-middle w-6"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "vs-button",
            {
              staticClass: "px-3 py-2 rounded-lg mr-1 ml-1",
              attrs: { type: "border" },
              on: { click: _vm.editResource }
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-nowrap" },
                [
                  _c("feather-icon", {
                    staticClass: "align-middle",
                    attrs: {
                      icon: "Edit2Icon",
                      svgClasses: "text-primary align-middle w-6"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "vs-button",
            {
              staticClass:
                "px-3 py-2 rounded-lg text-danger vs-button-danger mr-1 ml-1",
              attrs: { type: "border" },
              on: { click: _vm.askDeleteResource }
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-nowrap" },
                [
                  _c("feather-icon", {
                    staticClass: "align-middle",
                    attrs: {
                      icon: "TrashIcon",
                      svgClasses: "text-danger align-middle w-6"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }