var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SKModal", {
    ref: "modal",
    attrs: {
      actionButtonsPosition: "right",
      title:
        _vm.action === "create"
          ? _vm.$t("apps.configurator.customer-gallery.modal.create.title")
          : _vm.$t("apps.configurator.customer-gallery.modal.edit.title"),
      acceptCallback: _vm.saveResource,
      hasAcceptButton: _vm.imageUrl !== null
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { staticClass: "modalCustomerResource" }, [
              _c("div", { staticClass: "w-full pb-2" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "advice-container px-4 py-3 rounded relative text-primary flex"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mx-5" },
                      [
                        _c("feather-icon", {
                          staticClass: "align-middle",
                          attrs: {
                            icon: "InfoIcon",
                            svgClasses: "text-primary align-middle w-6"
                          }
                        })
                      ],
                      1
                    ),
                    _c("CustomerAssetUploadInfo")
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "mt-5 image-container rounded relative flex items-center justify-center"
                  },
                  [
                    _vm.imageUrl === null
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "empty-customer-img-container flex items-center justify-center flex-col flex-1"
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "align-middle",
                              attrs: {
                                icon: "ImageIcon",
                                svgClasses: "text-primary align-middle w-6"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "empty-img-legend mt-5" },
                              [_c("span", [_vm._v("(max. 500KB)")])]
                            )
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "w-100 h-auto object-scale-down flex rounded"
                          },
                          [
                            _c("img", {
                              staticClass: "rounded mx-auto max-w-full",
                              attrs: { src: _vm.imageUrl }
                            })
                          ]
                        )
                  ]
                ),
                _vm.imageUrl !== null
                  ? _c("p", { staticClass: "mt-2" }, [
                      _c("small", [
                        _vm._v(
                          _vm._s(_vm.name) +
                            " " +
                            _vm._s(_vm.dimensions) +
                            " " +
                            _vm._s(_vm.size)
                        )
                      ])
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "w-full flex actions-image-container" },
                  [
                    _c("div", { staticClass: "flex items-center mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "rounded-lg px-3 py-2 mr-2",
                              attrs: { type: "border" },
                              on: {
                                click: function($event) {
                                  return _vm.handlerFiles()
                                }
                              }
                            },
                            [_vm._v("Select image")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _vm.imageUrl !== null
                            ? _c(
                                "vs-button",
                                {
                                  staticClass:
                                    "px-3 py-1 rounded-lg text-danger vs-button-danger mr-2 ml-2",
                                  attrs: { type: "border" },
                                  on: { click: _vm.deleteResource }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex flex-nowrap" },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "align-middle",
                                        attrs: {
                                          icon: "TrashIcon",
                                          svgClasses:
                                            "text-danger align-middle w-6"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("vs-input", {
                            ref: "input-file",
                            attrs: { type: "file", name: _vm.name, hidden: "" },
                            on: { change: _vm.onFileChange }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ]),
              _vm.imageUrl !== null
                ? _c("div", [
                    _c(
                      "div",
                      [
                        _c("LabelForm", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "apps.configurator.customer-gallery.modal.name"
                              )
                            )
                          )
                        ]),
                        _c("vs-input", {
                          staticClass: "input-modal w-full border-grey-light",
                          attrs: { type: "text", maxlength: "100" },
                          on: { keyup: _vm.generateFinalName },
                          model: {
                            value: _vm.inputs.name,
                            callback: function($$v) {
                              _vm.$set(_vm.inputs, "name", $$v)
                            },
                            expression: "inputs.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "w-50 pr-1" },
                      [
                        _c("LabelForm", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "apps.configurator.customer-gallery.modal.select-mode.all"
                              )
                            )
                          )
                        ]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.inputs.mode,
                                expression: "inputs.mode"
                              }
                            ],
                            staticClass: "w-full border-grey-light input-modal",
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.inputs,
                                    "mode",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                _vm.generateFinalName
                              ]
                            }
                          },
                          _vm._l(_vm.timeofyearOpts, function(timeofyearOpt) {
                            return _c(
                              "option",
                              {
                                key: timeofyearOpt.value,
                                domProps: { value: timeofyearOpt.value }
                              },
                              [_vm._v(" " + _vm._s(timeofyearOpt.label) + " ")]
                            )
                          }),
                          0
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "w-50 pr-1" },
                      [
                        _c("LabelForm", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "apps.configurator.customer-gallery.modal.select-language"
                              )
                            )
                          )
                        ]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.inputs.lang,
                                expression: "inputs.lang"
                              }
                            ],
                            staticClass: "w-full border-grey-light input-modal",
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.inputs,
                                    "lang",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                _vm.generateFinalName
                              ]
                            }
                          },
                          _vm._l(_vm.langOpts, function(langOpt) {
                            return _c(
                              "option",
                              {
                                key: langOpt.value,
                                domProps: { value: langOpt.value }
                              },
                              [_vm._v(" " + _vm._s(langOpt.label) + " ")]
                            )
                          }),
                          0
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "pb-5" },
                      [
                        _c("LabelForm", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "apps.configurator.customer-gallery.modal.final-name"
                              )
                            )
                          )
                        ]),
                        _c("vs-input", {
                          staticClass: "input-modal w-full border-grey-light",
                          attrs: { disabled: true, type: "text" },
                          model: {
                            value: _vm.inputs.final_name,
                            callback: function($$v) {
                              _vm.$set(_vm.inputs, "final_name", $$v)
                            },
                            expression: "inputs.final_name"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }