<template>
    <div class="asset-card rounded-lg vs-con-textarea">
        <img :src="url" />
        <SKTooltip :text="assetName">
            <span class="block p-3 text-xs">{{ assetName }}</span>
        </SKTooltip>
        <span class="flex pl-3 pb-3 text-xs text-grey">{{ resolution }}</span>
        <div class="mt-1 mb-3">
            <vs-button type="border" class="px-3 py-2 rounded-lg mr-1 ml-3" @click="downloadResource" >
                <div class="flex flex-nowrap">
                    <feather-icon icon="DownloadIcon" svgClasses="text-primary align-middle w-6" class="align-middle"/>
                </div>
            </vs-button>
            <vs-button type="border" class="px-3 py-2 rounded-lg mr-1 ml-1" @click="editResource" >
                <div class="flex flex-nowrap">
                    <feather-icon icon="Edit2Icon" svgClasses="text-primary align-middle w-6" class="align-middle"/>
                </div>
            </vs-button>
            <vs-button type="border" class="px-3 py-2 rounded-lg text-danger vs-button-danger mr-1 ml-1" @click="askDeleteResource" >
                <div class="flex flex-nowrap">
                    <feather-icon icon="TrashIcon" svgClasses="text-danger align-middle w-6" class="align-middle"/>
                </div>
            </vs-button>
        </div>
    </div>
</template>

<script>
import ResourcesService from '@/modules/Apps/Services/Configurator/ConfiguratorResourcesService'
import modalCustomerResource from "@/modules/Shared/Components/modal/ModalCustomerResource";
import SKTooltip from "@/modules/Shared/Components/SKTooltip";

export default {
    components: {
        SKTooltip,
        ResourcesService,
        modalCustomerResource
    },
    props: {
        asset: String,
    },
    async created() {
        await this.getAssetInfo()
    },
    data() {
        return {
            resolution: '',
            modalOpen: false,
            resource: {
                url: null,
                filename: null
            }
        }
     },
    methods: {
        async getAssetInfo() {
            let parsedUrl = new URL(this.asset);
            let path = parsedUrl.pathname;
            let parts = path.split("/");
            let filename = parts[parts.length - 1];

            this.assetName = filename
            this.url = parsedUrl.toString()

            this.resource.url = this.url
            this.resource.filename = filename


            await this.getResolutionFromImg(this.url)
                .then((resolution) => {
                    this.resolution = resolution
                })
                .catch((error) => {
                    console.error(error.message);
                });
        },
        getResolutionFromImg(url) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = function () {
                    const resolution = this.width + 'px x' + this.height + 'px'
                    resolve(resolution);
                };
                img.onerror = function () {
                    reject(new Error('Could not load the required image'));
                };
                img.src = url;
            });
        },
        editResource() {
            this.$emit('editResource', this.resource)
        },
        downloadResource() {
            const downloadLink = document.createElement('a');
            downloadLink.href = this.url;
            downloadLink.target = 'blank';
            downloadLink.download = this.assetName;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },
        async deleteResource() {
            this.$vs.loading()
            try {
                 await ResourcesService.deleteCustomerResources(this.$route.params.versionUuid, this.assetName)
                this.$vs.notify({ text: this.$t("apps.configurator.customer-gallery.delete.ok"), color: 'success', position: 'top-right' })
            } catch( error ) {
                this.$vs.notify({ text: this.$t("apps.configurator.customer-gallery.delete.ko"), color: 'danger', position: 'top-right' })
            } finally {
                this.$vs.loading.close();
                this.modalOpen = false
                this.$emit('reloadCustomerResources')
            }
        },
        async askDeleteResource() {
            this.$vs.dialog({
                type:'confirm',
                color:'danger',
                title: this.$t('question.confirm'),
                text: this.$t("question.sure"),
                accept: () => this.deleteResource()
            })
        }
    }
}
</script>
<style>
.asset-card {
    box-shadow: 0px 3.07029390335083px 6.14058780670166px 0px rgba(0, 1, 27, 0.16);
    min-height: 280px;
    overflow: hidden;
}

.asset-card img {
    width: 100%;
    height: auto;
    object-fit: scale-down;
    height: 237px;
    @apply bg-background-grey !important;
}

.asset-card span {
    text-overflow: ellipsis;
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
}
</style>