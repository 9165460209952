var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-white py-5 px-10" },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-row vx-col w-full text-right items-center justify-between space-x-4 mb-4 mt-4"
        },
        [
          _c("div", { staticClass: "flex flex-col text-left pt-5 pb-5" }, [
            _c("h3", [
              _vm._v(
                _vm._s(_vm.$t("apps.configurator.resources.gallery.title"))
              )
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("apps.configurator.resources.gallery.subtitle"))
              )
            ])
          ]),
          _c("div", [
            _c(
              "button",
              {
                staticClass:
                  "inline-block mb-2 mr-2 rounded-lg sk-btn sk-btn-primary",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.createResource.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "apps.configurator.customer-gallery.upload-resource"
                      )
                    ) +
                    " "
                )
              ]
            )
          ])
        ]
      ),
      !_vm.customResources.length
        ? _c("emptyAlert", {
            attrs: {
              message: _vm.$t("apps.configurator.customer-gallery.no-assets")
            }
          })
        : _vm._e(),
      _vm.customResources.length
        ? _c(
            "div",
            {
              staticClass:
                "card-container rounded-lg vs-con-textarea px-5 py-5 flex flex-row flex-wrap w-full items-center"
            },
            _vm._l(_vm.customResources, function(customResource) {
              return _c(
                "div",
                { key: customResource, staticClass: "w-1/4 py-3 px-2" },
                [
                  _c("CustomerAsset", {
                    attrs: { asset: customResource },
                    on: {
                      editResource: _vm.editResource,
                      reloadCustomerResources: _vm.reloadCustomerResources
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "div",
        [
          _c("modalCustomerResource", {
            ref: "modalCustomerResource",
            attrs: {
              resource: _vm.resource,
              action: _vm.modalCustomerResourceAction
            },
            on: { reloadCustomerResources: _vm.reloadCustomerResources }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }