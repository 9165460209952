<template>
    <SKModal
        ref="modal"
        actionButtonsPosition="right"
        :title="action === 'create' ? $t('apps.configurator.customer-gallery.modal.create.title') : $t('apps.configurator.customer-gallery.modal.edit.title')"
        :acceptCallback="saveResource"
        :hasAcceptButton="imageUrl !== null"
    >
        <template v-slot:body>
            <div class="modalCustomerResource">
                <div class="w-full pb-2">
                    <div class="advice-container px-4 py-3 rounded relative text-primary flex">
                        <div class="mx-5">
                            <feather-icon icon="InfoIcon" svgClasses="text-primary align-middle w-6" class="align-middle" />
                        </div>
                        <CustomerAssetUploadInfo />
                    </div>

                    <div class="mt-5 image-container rounded relative flex items-center justify-center">
                        <div v-if="imageUrl === null"
                            class="empty-customer-img-container flex items-center justify-center flex-col flex-1">
                            <feather-icon icon="ImageIcon" svgClasses="text-primary align-middle w-6" class="align-middle" />
                            <div class="empty-img-legend mt-5">
                                <span>(max. 500KB)</span>
                            </div>
                        </div>
                        <div class="w-100 h-auto object-scale-down flex rounded" v-else>
                            <img class="rounded mx-auto max-w-full" :src='imageUrl' />
                        </div>
                    </div>
                    <p class="mt-2" v-if="imageUrl !== null">
                        <small>{{ name }} {{ dimensions }} {{ size }}</small>
                    </p>
                    <div class="w-full flex actions-image-container">
                        <div class="flex items-center mt-2">
                            <div class="flex">
                                <vs-button type="border" class="rounded-lg px-3 py-2 mr-2" @click="handlerFiles()">Select image</vs-button>
                            </div>
                            <div class="flex">
                                <vs-button v-if="imageUrl !== null" type="border"
                                    class="px-3 py-1 rounded-lg text-danger vs-button-danger mr-2 ml-2" @click="deleteResource">
                                    <div class="flex flex-nowrap">
                                        <feather-icon icon="TrashIcon" svgClasses="text-danger align-middle w-6" class="align-middle" />
                                    </div>
                                </vs-button>
                            </div>
                            <div class="flex">
                                <vs-input ref="input-file" type="file" :name="name" hidden @change="onFileChange" />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="imageUrl !== null">
                    <div>
                        <LabelForm>{{ $t("apps.configurator.customer-gallery.modal.name") }}</LabelForm>
                        <vs-input @keyup="generateFinalName" type="text" maxlength="100" class="input-modal w-full border-grey-light"
                            v-model="inputs.name" />
                    </div>
                    <div class="w-50 pr-1">
                        <LabelForm>{{ $t("apps.configurator.customer-gallery.modal.select-mode.all") }}</LabelForm>
                        <select class="w-full border-grey-light input-modal" v-model="inputs.mode" @change="generateFinalName">
                            <option :key="timeofyearOpt.value" :value="timeofyearOpt.value"
                                v-for="timeofyearOpt in timeofyearOpts">
                                {{ timeofyearOpt.label }}
                            </option>
                        </select>
                    </div>
                    <div class="w-50 pr-1">
                        <LabelForm>{{ $t("apps.configurator.customer-gallery.modal.select-language") }}</LabelForm>
                        <select class="w-full border-grey-light input-modal" v-model="inputs.lang" @change="generateFinalName">
                            <option v-for="langOpt in langOpts" :key="langOpt.value" :value="langOpt.value" >
                                {{ langOpt.label }}
                            </option>
                        </select>
                    </div>
                    <div class="pb-5">
                        <LabelForm>{{ $t("apps.configurator.customer-gallery.modal.final-name") }}</LabelForm>
                        <vs-input :disabled="true" type="text" class="input-modal w-full border-grey-light"
                            v-model="inputs.final_name" />
                    </div>
                </div>
            </div>
         </template>
    </SKModal>
</template>
<script>

import { mapGetters } from 'vuex'
import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import DynamicForm from "@/modules/Shared/Components/form/DynamicForm"
import AppsService from "@/modules/Apps/Services/AppsService"
import ResourcesService from '@/modules/Apps/Services/Configurator/ConfiguratorResourcesService'
import CustomerAssetUploadInfo from '@/modules/Apps/Components/resources/gallery/CustomerAssetUploadInfo'
import SKModal from "@/modules/Shared/Components/modal/SKModal";

export default {
    name: 'modal-customer-resource',
    props: {
        resource: Object,
        action: String
    },
    components: {
        LabelForm,
        DynamicForm,
        ResourcesService,
        SKModal,
        CustomerAssetUploadInfo,
    },
    computed: {
        ...mapGetters(['applicationUuid']),
    },
    methods: {
        open() {
            this.$refs['modal'].open()
        },
        handlerFiles() {
            this.$refs['input-file'].$refs.vsinput.click()
        },
        async onFileChange(event) {
            const file = event.target.files[0]
            if (file && file.type.startsWith('image/')) {
                this.imageUrl = URL.createObjectURL(file);
                this.name = file.name
                this.size = `(${(file.size / 1024).toFixed(2)} KB)`

                await this.getImageData(this.imageUrl)
            }
        },
        async getImageData(imageUrl) {
            await this.getImageDimensions(imageUrl)
                .then((resolution) => {
                    this.dimensions = resolution
                })
                .catch((error) => {
                    console.error(error.message);
                });

            this.inputs.mode = this.inputs.mode || 'winter'
            this.inputs.lang = this.inputs.lang || this.$store.state.AppActiveUser.language

            const filename = this.name.split('.')
            this.inputs.extension = filename.pop()
            this.inputs.name = this.name.split('.')[0]

            this.generateFinalName()
        },
        async getImageDimensions(url) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = function () {
                    const resolution = this.width + 'x' + this.height + ' px'
                    resolve(resolution);
                };
                img.onerror = function () {
                    reject(new Error('Could not load the required image'));
                };
                img.src = url;
            });
        },
        async getFileSize(url) {
            try {
                const response = await fetch(url, { method: 'HEAD' });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const contentLengthHeader = response.headers.get('content-length');
                if (contentLengthHeader) {
                    const fileSizeInBytes = parseInt(contentLengthHeader, 10);
                    const fileSizeInKB = fileSizeInBytes / 1024;

                    return fileSizeInKB;
                }
            } catch (error) {
                return null;
            }
        },
        async getImageAsBase64(url) {
            try {
                const response = await fetch(url);

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const blob = await response.blob();
                const reader = new FileReader();

                return new Promise((resolve, reject) => {
                    reader.onloadend = () => {
                        const base64Data = reader.result.split(',')[1];
                        resolve(base64Data);
                    };

                    reader.onerror = (error) => {
                        reject(error);
                    };

                    reader.readAsDataURL(blob);
                });
            } catch (error) {
                console.error('Error al obtener la imagen en base64:', error.message);
                return null;
            }
        },
        async saveResource() {
            let body = {
                image_name: this.inputs.final_name,
            }
            await this.getImageAsBase64(this.imageUrl)
                .then(base64Data => {
                    body.image = base64Data
                })
                .catch(error => {
                    console.error('Error while trying to conver image to Base64:', error);
                });

            try {
                this.$vs.loading()
                await ResourcesService.uploadCustomerResources(this.$route.params.versionUuid, body)
                this.$vs.notify({ text: this.$t("apps.configurator.customer-gallery.updated.ok"), color: 'success', position: 'top-right' })
            } catch( error ) {
                this.$vs.notify({ text: this.$t("apps.configurator.customer-gallery.updated.ko"), color: 'danger', position: 'top-right' })
            } finally {
                this.$vs.loading.close();
                this.$emit('reloadCustomerResources')
            }

            return false
        },
        generateFinalName() {
            let acronym = this.inputs.lang !== '' ? `_${this.inputs.lang}` : '';
            let season = this.inputs.mode === 'summer' ? '_summer' : '';
            this.inputs.final_name = `${this.inputs.name}${season}${acronym}.${this.inputs.extension}`
        },
        clearModal() {
            this.imageUrl = null
            this.dimensions = null
            this.size = null
            this.name = null
            this.inputs.name = null
            this.inputs.mode = 'winter'
            this.inputs.lang = null
            this.inputs.extension = null
            this.inputs.final_name = null
        },
        async deleteResource() {
            this.$vs.loading()
            try {
                await ResourcesService.deleteCustomerResources(this.$route.params.versionUuid, this.resource.filename)
                this.$vs.notify({ text: this.$t("apps.configurator.customer-gallery.delete.ok"), color: 'success', position: 'top-right' })
            } catch (error) {
                this.$vs.notify({ text: this.$t("apps.configurator.customer-gallery.delete.ko"), color: 'danger', position: 'top-right' })
            } finally {
                this.$vs.loading.close();
                this.closeResource()
            }
        },
        getFileNameFromFullName(name) {
            this.langOpts.map(function(langOpt) {
                name = name.replace('_'+langOpt.value+'.', '.')
            })
            return name.replace('_summer', '')
        },
        getSeasonFromFullName(name) {
            return name.includes("_summer") ? 'summer' : 'winter';
        },
        getLanguageFromFullName(name) {
            let filteredLangs = this.langOpts.filter(function(langOpt) {
                return name.includes("_" + langOpt.value)
            })
            if (filteredLangs.length <= 0) {
                return null;
            }

            return filteredLangs[0].value
        }
    },

    async created() {
        this.clearModal()
        this.timeofyearOpts = [
            { label: this.$t("apps.configurator.customer-gallery.modal.select-mode.winter"), value: 'winter' },
            { label: this.$t("apps.configurator.customer-gallery.modal.select-mode.summer"), value: 'summer' },
        ]

        let apiResponse = await AppsService.basicAppInfo(this.$route.params.uuid)
        this.languages = apiResponse.data.object.languages

        this.langOpts = this.languages.map(lang => ({
            label: lang.acronym,
            value: lang.acronym,
        }))
        this.langOpts.push({
            label: 'All languages',
            value: ''
        })

    },
    watch: {
        'resource': async function() {
            this.clearModal()
            if (this.resource.url) {
                this.imageUrl = this.resource.url
                this.name = this.getFileNameFromFullName(this.resource.filename),
                this.size = await this.getFileSize(this.resource.url)
                this.inputs.mode = this.getSeasonFromFullName(this.resource.filename)
                this.inputs.lang = this.getLanguageFromFullName(this.resource.filename)
                await this.getImageData(this.resource.url)
            }
        }
    },
    data() {
        return {
            imageUrl: null,
            dimensions: null,
            size: null,
            name: null,
            inputs: {
                name: null,
                mode: 'winter',
                lang: null,
                extension: null,
                final_name: null
            },
            langOpts: [],
            timeofyearOpts: [],
        }
    },
}
</script>
<style scoped>
.modalCustomerResource .image-container {
    @apply bg-background-grey;
    min-height: 299px;
}
</style>