var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full space-x-4" },
        [
          _c(
            "b-tabs",
            { staticClass: "m-0" },
            [
              _c(
                "b-tab",
                {
                  attrs: { active: !_vm.isEditor },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("span", { staticClass: "font-semibold" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "apps.configurator.gallery.standard-resources"
                                )
                              )
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm.resource
                    ? _c("MainGallery", { attrs: { resources: _vm.resource } })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  staticClass: "mb-10",
                  attrs: { active: _vm.isEditor },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("span", { staticClass: "font-semibold" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "apps.configurator.gallery.customer-resources"
                                )
                              )
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [_c("CustomerGallery")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }