<template>
    <div class=" flex flex-column w-full bg-white p-5 rounded-md m-1">
        <div class="p-5">
            <h3>{{ $t("apps.configurator.resources.gallery.title") }}</h3>
            <span>{{ $t("apps.configurator.resources.gallery.subtitle") }}</span>
        </div>
        <div class="p-5">
            <span class="font-medium">{{ $t("apps.configurator.resources.gallery.selector-text") }}</span>
                <vs-select class="w-25 border-grey-light" v-model="defaultGalleryOpt" v-on:change="loadGallery">
                    <vs-select-item :key="galleryOpt.value" :value="galleryOpt.value" :text="galleryOpt.label"
                        v-for="galleryOpt in galleryOpts" />
                </vs-select>
        </div>
        <div class="p-5">
            <GalleryList :galleryList="galleryData"/>
        </div>
    </div>
</template>

<script>
import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import GalleryList from '@/modules/Apps/Components/resources/gallery/GalleryList'

export default {
    components: {
        LabelForm,
        GalleryList
    },
    props: {
        resources: Object,
    },
    computed: {
        isEditor() {
            return this.$store.state.auth.drm['admin'].length === 0
        }
    },
    data() {
        return {
            defaultGalleryOpt: "all",
            galleryData: [],
            galleryOpts: [
                { label: this.$t('apps.configurator.resources.gallery.selector-all-resources'), value: "all" },
                { label: this.$t('apps.configurator.resources.gallery.selector-dynamic-android-summer'), value: "dynamic-android-summer" },
                { label: this.$t('apps.configurator.resources.gallery.selector-dynamic-android-winter'), value: "dynamic-android-winter" },
                { label: this.$t('apps.configurator.resources.gallery.selector-dynamic-ios-winter'), value: "dynamic-ios-winter" },
                { label: this.$t('apps.configurator.resources.gallery.selector-dynamic-ios-summer'), value: "dynamic-ios-summer" },
                { label: this.$t('apps.configurator.resources.gallery.selector-static-ios'), value: "static-ios" },
                { label: this.$t('apps.configurator.resources.gallery.selector-static-android'), value: "static-android" },
            ]

        }
    },
    async created() {
        if (this.isEditor) {
            this.galleryOpts = this.galleryOpts.filter((item) => !item.value.includes('static'))
        }
        this.loadGallery("all")
    },
    methods: {
        async loadGallery(galleryType) {
            switch(galleryType) {
                case "dynamic-android-summer":
                    this.galleryData = this.getDynamicAndroid('dynamic-android-summer')
                    break
                case "dynamic-android-winter":
                this.galleryData = this.getDynamicAndroid('dynamic-android-winter')
                    break
                case "dynamic-ios-summer":
                    this.galleryData = this.getDynamicIOS('dynamic-ios-summer')
                    break
                case "dynamic-ios-winter":
                    this.galleryData = this.getDynamicIOS('dynamic-ios-winter')
                    break
                case "static-android":
                    this.galleryData = this.getStaticAndroid()
                    break
                case "static-ios":
                    this.galleryData = this.getStaticIOS()
                    break
                case "all":
                    this.galleryData = this.getAll()
                    break
            }
        },
        getDynamicAndroid(dynamicType) {
            return [{
                type: "Dynamic",
                key: dynamicType,
                platforms: [
                    {
                        key: 1,
                        platform: "Android",
                        timeofyear: dynamicType.includes("winter") ? 'Winter' : 'Summer',
                        assets:  this.resources.files[dynamicType].map(this.getAssetUrl)
                    }
                ]
            }]
        },
        getDynamicIOS(dynamicType) {
            return [{
                type: "Dynamic",
                key: dynamicType,
                platforms: [
                    {
                        key: 2,
                        platform: "IOS",
                        timeofyear: dynamicType.includes("winter") ? 'Winter' : 'Summer',
                        assets:  this.resources.files[dynamicType].map(this.getAssetUrl)
                    }
                ]
            }]
        },
        getStaticAndroid() {
            return [{
                type: "Static",
                key: 'static-android',
                platforms: [
                    {
                        key: 3,
                        platform: "Android",
                        timeofyear: null,
                        assets:  this.resources.files["static-android"].map(this.getAssetUrl)
                    }
                ]
            }]
        },
        getStaticIOS() {
            return [{
                type: "Static",
                key: 'static-ios',
                platforms: [
                    {
                        key: 4,
                        platform: "IOS",
                        timeofyear: null,
                        assets:  this.resources.files["static-ios"].map(this.getAssetUrl)
                    }
                ]
            }]
        },
        getAll() {
            let options = [
                {
                    type: "Dynamic",
                    key: 'all-dynamic',
                    platforms: [
                        {...this.getDynamicAndroid('dynamic-android-summer')[0].platforms[0], key: 7},
                        {...this.getDynamicAndroid('dynamic-android-winter')[0].platforms[0], key: 8},
                        {...this.getDynamicIOS('dynamic-ios-summer')[0].platforms[0], key: 9},
                        {...this.getDynamicIOS('dynamic-ios-winter')[0].platforms[0], key: 10},
                    ]
                }
            ]

            if (!this.isEditor) {
                options = options.slice();
                options.unshift({
                    type: "Static",
                    key: 'all-static',
                    platforms: [
                        {...this.getStaticAndroid()[0].platforms[0], key: 5},
                        {...this.getStaticIOS()[0].platforms[0], key:6 },

                    ]
                });
            }

            return options
        },
        getAssetUrl(assetPath) {
            return 'https://appresources.skitude.com/' + assetPath
        }
    }
}
</script>
