<template>
    <div>
        <div v-for="gallery in galleryList" :key="gallery.key">
            <div class="my-7" v-for="platform in gallery.platforms" :key="platform.key">
                <h4>{{ getSectionName(gallery, platform) }}</h4>
                <emptyAlert v-if="!platform.assets.length" :message="$t('apps.configurator.customer-gallery.no-assets')" />
                <div v-if="platform.assets.length" class="flex flex-column vs-con-textarea my-2 rounded-lg p-5 mt-5">
                    <Gallery :asset-list="platform.assets"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import emptyAlert from '@/modules/Shared/Components/alert/emptyAlert'
import Gallery from "@/modules/Apps/Components/resources/gallery/Gallery.vue";

export default {
    components: {
        Gallery,
        emptyAlert,
    },
    props: {
        galleryList: Array,
    },
    methods: {
        getSectionName(gallery, platform) {
            let name = `${gallery.type} ${platform.platform}`;
            if (platform.timeofyear) {
                name = `${name} ${platform.timeofyear}`;
            }
            return name
        }
    }
}
</script>
