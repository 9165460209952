var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: " flex flex-column w-full bg-white p-5 rounded-md m-1" },
    [
      _c("div", { staticClass: "p-5" }, [
        _c("h3", [
          _vm._v(_vm._s(_vm.$t("apps.configurator.resources.gallery.title")))
        ]),
        _c("span", [
          _vm._v(_vm._s(_vm.$t("apps.configurator.resources.gallery.subtitle")))
        ])
      ]),
      _c(
        "div",
        { staticClass: "p-5" },
        [
          _c("span", { staticClass: "font-medium" }, [
            _vm._v(
              _vm._s(
                _vm.$t("apps.configurator.resources.gallery.selector-text")
              )
            )
          ]),
          _c(
            "vs-select",
            {
              staticClass: "w-25 border-grey-light",
              on: { change: _vm.loadGallery },
              model: {
                value: _vm.defaultGalleryOpt,
                callback: function($$v) {
                  _vm.defaultGalleryOpt = $$v
                },
                expression: "defaultGalleryOpt"
              }
            },
            _vm._l(_vm.galleryOpts, function(galleryOpt) {
              return _c("vs-select-item", {
                key: galleryOpt.value,
                attrs: { value: galleryOpt.value, text: galleryOpt.label }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "p-5" },
        [_c("GalleryList", { attrs: { galleryList: _vm.galleryData } })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }