<template>
    <div>
        <div class="vx-row">
            <div class="vx-col w-full space-x-4">
                <b-tabs class="m-0">
                    <b-tab :active="!isEditor">
                        <template #title>
                            <span class="font-semibold">{{ $t('apps.configurator.gallery.standard-resources') }}</span>
                        </template>
                        <MainGallery v-if="resource" :resources="resource"/>
                     </b-tab>
                     <b-tab :active="isEditor" class="mb-10">
                        <template #title>
                            <span class="font-semibold">{{ $t('apps.configurator.gallery.customer-resources') }}</span>
                        </template>
                        <CustomerGallery />
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import MainGallery from '@/modules/Apps/Components/resources/gallery/MainGallery'
import CustomerGallery from '@/modules/Apps/Components/resources/gallery/CustomerGallery'
import ResourceService from '@/modules/Apps/Services/Configurator/ConfiguratorResourcesService'
import PaginationService from '@/modules/Shared/Services/PaginationService'
import loader from "@/modules/Shared/Mixins/loader.js";
import ConfiguratorVersionService from "@/modules/Apps/Services/Configurator/ConfiguratorVersionService";
import store from "@/modules/Shared/Store/store";

export default {
    components: {
        LabelForm,
        MainGallery,
        CustomerGallery
    },
    mixins: [loader],
    data() {
        return { resource: undefined }
    },
    async beforeRouteEnter (to, from, next) {
        const version = await ConfiguratorVersionService.getVersion(to.params.versionUuid);
        await store.dispatch('setRouteElement', version)
        next()
    },
    computed: {
        isEditor() {
            return this.$store.state.auth.drm['admin'].length === 0
        }
    },
    async created () {
        this.loadAndNotify(async () => {
            let params = {
                page: 1,
                filters: [{ field: "version_uuid", value: this.$route.params.versionUuid }]
            }
            let response =  await ResourceService.getResources(PaginationService.parseParameters(params))
            this.resource = response.data[0]
        }, true)
    }
}
</script>
