var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.galleryList, function(gallery) {
      return _c(
        "div",
        { key: gallery.key },
        _vm._l(gallery.platforms, function(platform) {
          return _c(
            "div",
            { key: platform.key, staticClass: "my-7" },
            [
              _c("h4", [_vm._v(_vm._s(_vm.getSectionName(gallery, platform)))]),
              !platform.assets.length
                ? _c("emptyAlert", {
                    attrs: {
                      message: _vm.$t(
                        "apps.configurator.customer-gallery.no-assets"
                      )
                    }
                  })
                : _vm._e(),
              platform.assets.length
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-column vs-con-textarea my-2 rounded-lg p-5 mt-5"
                    },
                    [
                      _c("Gallery", {
                        attrs: { "asset-list": platform.assets }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }